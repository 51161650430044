<script setup>
import useOauth from "@/components/ExternalApps/SalesChannelManagementApp/composables/useOauth";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { defineProps } from "vue";
const props = defineProps({
  integration: {
    type: Object,
    default: () => {}
  }
});

const {
  authorizationUrl,
  value,
  authSuccess,
  formConfig,
  form,
  selectedAppName
} = useOauth(props.integration);
</script>

<template>
  <div class="text-left">
    <FormHelper
      v-if="form.length && !authSuccess"
      v-model="value"
      :config="formConfig"
      :form="form"
    />
    <a
      v-if="!authSuccess"
      :href="authorizationUrl"
      class="btn btn-primary my-4"
      target="_parent"
      >{{ $t(`salesChannelManagementApp.${selectedAppName}.oAuthFlowHelp`) }}</a
    >
    <div v-if="authSuccess" class="alert alert-success">
      {{ $t(`salesChannelManagementApp.${selectedAppName}.authSuccess`) }}
    </div>
  </div>
</template>
