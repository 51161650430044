import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
const url = process.env.VUE_APP_API_ADMIN;
const basePath = "/apps/checkExternalConnection/";
const baseUrl = url + basePath;

class ExternalConnection extends BaseService {
  check(params = {}) {
    return ApiService.post(baseUrl, params);
  }
}
export default new ExternalConnection();
